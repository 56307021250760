<template>
  <button class="btn btn-primary" @click="clickHandler">
    {{ $t('general.btn.edit') }}
  </button>
</template>

<script>
export default {
  name: 'edit-btn',
  props: ['projectUuid'],
  methods: {
    clickHandler() {
      window.open(`${process.env.VUE_APP_AGENT_URL}/listing/edit/${this.projectUuid}`);
    },
  },
};
</script>

<style scoped></style>
