<template>
  <div>
    <edit-btn :project-uuid="project.uuid" />
  </div>
</template>

<script>
import EditBtn from '@/components/project-detail/partials/edit-btn';

export default {
  name: 'owner-section',
  components: {
    EditBtn,
  },
  props: ['project', 'checkBankAccountDone'],
  methods: {
    actionDoneHandler() {
      this.$router.go(0);
    },
  },
};
</script>

<style scoped></style>
